import React, { useState, useEffect, useRef } from "react"
import { Container, Section, M, GrayBorder } from "../../Global"
import ICategoryTemplate from "./types";
import CategoryCard from "./CategoryCard";

const CategoryListTemplate = ({ categoriesData }: ICategoryTemplate) => {

  const [detailActive, setDetailActive] = useState<boolean>(false)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const toggleDetails = () => {
    setDetailActive(!detailActive)
  }

  useEffect(() => {
    setHeight(ref.current.clientHeight ? ref.current.clientHeight : 0)

    function handleResize() {
      setHeight(ref.current.clientHeight ? ref.current.clientHeight : 0)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  })

  const categoriesComponents = categoriesData.map((category) => {
    return <CategoryCard
        categoryName={category.categoryName}
        categoryDescription={category.categoryDescription}
        categorySlug={category.categorySlug}
        categoryTags={category.categoryTags}
    />
  })

  return (
    <>
      <Section>
        <Container>
          <M>
            <div ref={ref}>{categoriesComponents}</div>
          </M>
        </Container>
      </Section>
      <GrayBorder />
    </>
  )
}

export default CategoryListTemplate
