import React, {ReactElement} from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Global/Head"

import CategoryListTemplate from "../components/Sections/CategoryListTemplate";

const CategoryListTemplatePage = ({ pageContext } : any): ReactElement => {
    const categoryTemplateData = {
        categoriesData: pageContext.categoriesData,
    }

    return (
        <Layout hideLanding={true}>
            <Head
                pageTitle="Platforma Treningowa Online" //todo
                description="" //todo
                keywords="trening online, platforma treningowa online, video trening" //todo
            />
            <CategoryListTemplate categoriesData={categoryTemplateData.categoriesData} />
        </Layout>
    )
}

export default CategoryListTemplatePage
