import * as React from "react"
import {
  CategoryCardContainer,
  CategoryCardWrapper,
  ImageWrapper,
  ContentWrapper,
  H3CategoryTitle,
  P,
} from "./styles"

import { StaticImage } from "gatsby-plugin-image"
import ICategoryCard from "./types";
import RightArrowButton from "../../../UI/RightArrowButton";
import {PillTag, PillTagsContainer } from "../../../Global";

const CategoryCard = ({categoryName, categoryDescription, categorySlug, categoryTags }: ICategoryCard) => {

  const tagComponents = categoryTags.map((tag) => {
    return <PillTag>{tag}</PillTag>
  })

  return (
    <CategoryCardContainer>
      <CategoryCardWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../../../data/images/mock/fitness_mock.jpg"
            alt="An image"
          />
        </ImageWrapper>
        <ContentWrapper>
          <H3CategoryTitle>{categoryName}</H3CategoryTitle>
          <PillTagsContainer>
            {tagComponents}
          </PillTagsContainer>
          <P>{categoryDescription}</P>
          <div>
          </div>
        </ContentWrapper>
        <RightArrowButton slug={categorySlug} />
      </CategoryCardWrapper>
    </CategoryCardContainer>
  )
}

export default CategoryCard
