import * as React from "react"
import arrow_right from '../../../data/images/arrow_right.png'

import {
    RightArrowButtonStyle
} from "./styles"
import IArrowButton from "./types";
import {Link} from "gatsby";

const RightArrowButton = ({slug}: IArrowButton) => {

    return (
            <RightArrowButtonStyle>
                <Link to={"/category/" + slug}><img src={arrow_right} alt="my image"/></Link>
            </RightArrowButtonStyle>
    )
}

export default RightArrowButton