import styled from "styled-components"
import { FlexCenter, Container, FlexCol } from "../../../Global"

export const CategoryCardContainer = styled(FlexCenter)`
  width: 100%;
`

export const CategoryCardWrapper = styled(Container)`
  display: grid;
  grid-template-columns: 330px 1fr 150px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  @media (max-width: 730px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px;
    grid-template-areas: ". ";
  }
`
export const ImageWrapper = styled.div`
  padding: 20px 20px 20px 15px;
  & > div {
    height: 100%;
  }
`

export const ContentWrapper = styled(FlexCol)`
  margin: 0px 20px 0px 20px;
`

export const H3CategoryTitle = styled.h3`
  margin-bottom: 0px;
  font-size: 36px;
  @media (max-width: 730px) {
    margin-top: 0px;
    font-size: 26px;
  }
`
export const P = styled.p`
  font-size: 18px;
  height: 100px;
  width: 1fr;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
`
